import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import Printer from '../../assets/images/cms/print.svg';

@Component({
  name: 'print-button'
})
export default class PrintButton extends Vue {
  @Prop()
  printCallback: () => void = () => {};
  icon: string = Printer;
}
