import axios from "axios";
import { Component, Mixins } from "vue-property-decorator";

import CmsTagComponent from "@/commoncomponents/CmsTagComponent/CmsTagComponent.vue";
import CmsLabelComponent from "@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue";
import CmsCardCarousel from "@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue";
import BouncingPreloaderComponent from "@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue";
import CmsNotAuthorizedComponent from "@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue";
import PrintButton from "@/commoncomponents/PrintButton/PrintButton.vue";
import ExcludePrint from '@/commoncomponents/ExcludePrint/ExcludePrint.vue';
import CmsContentfulComponent from "@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue";
import { Elements } from '@/commoncomponents/ExcludePrint/ExcludePrint';

import DeviceWidthCheckerMixin from "@/mixins/deviceWidthChecker.mixin";
import { getContentfulGraphQLQuery, getRelatedContentfulGraphQLQuery } from "@/utilities/contentfulGraphQLHelpers";
import {
  MediaResourcePageData,
  getCopyrightMessage,
  mediaResourceResponseToData,
  isAuthorizedContent
} from "@/utilities/cmsUtilities";

import APP_CONST from "@/constants/AppConst";

@Component({
  components: {
    "cms-tag-list": CmsTagComponent,
    "cms-content-label": CmsLabelComponent,
    "card-carousel": CmsCardCarousel,
    "bouncing-preloader": BouncingPreloaderComponent,
    "cms-not-authorized": CmsNotAuthorizedComponent,
    "print-button": PrintButton,
    "exclude-print": ExcludePrint,
    "cms-contentful-render": CmsContentfulComponent,
  }
})
export default class MediaResourceLongLandingPage extends Mixins(
  DeviceWidthCheckerMixin
) {

  isLoading: boolean = true;
  isAuthorized: boolean = true;
  isPrinting: boolean = false;
  resourceId: string = "";
  pageData: MediaResourcePageData | null = null;
  richContentElementsToExclude: string[] = [Elements.VIDEO];

  getCopyrightMessage = getCopyrightMessage;

  created() {
    window.addEventListener('beforeprint', this.handleBeforePrint);
    window.addEventListener('afterprint', this.handleAfterPrint);
  }

  beforeMount() {
    const id = this.$route.query.id;
    if (id) {
      this.resourceId = id.toString();
    }
    this.$watch("$route", this.onRouteChange);
    this.fetchData();
  }

  destroyed() {
    window.removeEventListener('beforeprint', this.handleBeforePrint);
    window.removeEventListener('afterprint', this.handleAfterPrint);
  }

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    return axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getContentfulGraphQLQuery("mediaResourceLong", this.resourceId)
      }
    })
      .then(result => {
        let mediaResourceLongData = result;
        // Get related content
        axios({
          url: url,
          headers: headers,
          method: method,
          data: {
            query: getRelatedContentfulGraphQLQuery("mediaResourceLong", this.resourceId, false)
          }
        }).then(relatedResult => {
          mediaResourceLongData.data.data.mediaResourceLong = {
            ...mediaResourceLongData.data.data.mediaResourceLong,
            ...relatedResult.data.data.mediaResourceLong
          };
          const structuredData = mediaResourceResponseToData("mediaResourceLong", result);
          this.isAuthorized = isAuthorizedContent(structuredData);

          this.pageData = {
            ...structuredData
          };

          this.isLoading = false;

          return structuredData;
        }).catch(err => {
          console.error(`Failed to Fetch Related Content`, err);
        });
      }).catch(err => {
        console.error(`Failed to Fetch Landing Page`, err);
      });
  }

  handlePrint() {
    this.isPrinting = true;
    this.$forceUpdate();
    this.$nextTick(() => {
      setTimeout(() => {
        print();
        this.$forceUpdate();
      }, 1000);
    });
  }

  handleAfterPrint() {
    this.isPrinting = false;
    this.$forceUpdate();
  }

  handleBeforePrint() {
    this.isPrinting = true;
  }

  onRouteChange() {
    const id: string = this.$route.query.id as string || '';
    this.isLoading = true;
    this.resourceId = id || "";
    this.fetchData();
  }
}