import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

export enum Elements {
  VIDEO = 'video'
}

@Component({
  name: 'exclude-print'
})
export default class ExcludePrint extends Vue {
  @Prop()
  elements: Elements[] = [];

  get excludeElements() {
    return this.elements.join(',');
  }
}
